<!--阅卷填空题-->
<template>
  <div class="maincontent">
    <div style="display: flex; font-size: 26px; padding: 24px 21px" @click="goback">
      <div style="color: #bababa">&lt;</div>
      <div style="font-weight: bold; color: #000000; margin-left: 17px">
        阅卷
      </div>
    </div>
    <div
      style="
        display: flex;
        font-size: 20px;
        color: #bababa;
        margin-left: 60px;
        width: 50%;
        justify-content: space-between;
        margin-bottom: 36px;
      "
    >
      <div style="display: flex">
        <div>等级：</div>
        <div>答应</div>
      </div>
      <div style="display: flex">
        <div>答题地区：</div>
        <div>杭州</div>
      </div>
      <div style="display: flex">
        <div>答题时间：</div>
        <div>2021年10月1日</div>
      </div>
    </div>
    <!--填空题-->
    <div>
      <div class="title">
        <div class="line"></div>
        <div>填空题</div>
      </div>
     <div v-for='(item,index) in list3' :key='index'>

        <div style="margin: 24px 93px 30px 81px; font-size: 20px;display:flex;justify-content:space-between">
        <div>
            {{item.questionContent}}
        </div>
        <div style="color:red;font-size: 20px">
            {{item.userScore}}分
        </div>
      </div>
      <div
        style="
          color: #bababa;
          font-size: 20px;
          display: flex;
          margin: 0 0 30px 95px;
        "
      >
        <div>参考答案：</div>
        <div>{{item.questionAnswer}}</div>
        
      </div>
      <div
        style="
          color: #bababa;
          font-size: 20px;
          display: flex;
          margin: 0 0 30px 95px;
        "
      >
        <div>考生答案：</div>
        <div>{{item.userQuestionAnswer}}</div>
        
      </div>
     </div>
      <div
        style="
          color: #bababa;
          font-size: 20px;
          display: flex;
          margin: 0 0 30px 198px;
        "
      >
      </div>
      <div>
      </div>
    </div>
    <!--主观题-->
    <div >
      <div class="title">
        <div class="line"></div>
        <div>主观题</div>
      </div>
      <div style="display: flex; flex-direction: column; margin: 30px 81px" v-for='(item,index) in list4' :key='index'>
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: #000;
          "
        >
          <div style="display: flex; align-items: center">
            <div>{{item.questionContent}}</div>
            <div style="color: #bababa; margin: 0 12px">({{item.score}}分)</div>
            <div style="color: #409eff" @click="lookanswer">
              查看答案<img
                v-show="answer"
                src="../../images/down.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
              <img
                v-show="!answer"
                src="../../images/up.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
            </div>
            
          </div>
          <div style="font-size:20px;color:red">{{item.userScore}}分</div>
        </div>
        <div style="display: flex">
          <div style="margin-top: 15px; color: #bababa; font-size: 20px">
            考生答案：
          </div>
          <div
            style="
              width: 902px;
              max-height: 257px;
              overflow: auto;
              border: 1px solid #cacaca;
              padding: 14px;
              margin: 15px 0;
              font-size: 16px;
              line-height: 34px;
              min-height:200px
            "
          >
            {{item.userQuestionAnswer}}
          </div>
        </div>
        <div style="display:flex;justify-content;justify-content: flex-end;">
          <div
            style="
              width: 902px;
              max-height: 257px;
              overflow: auto;
              border: 1px solid #cacaca;
              padding: 14px;
              margin: 15px 0;
              font-size: 16px;
              line-height: 34px;
              color: #cacaca;
              min-height:200px
            "
            v-show="answer"
          >
           {{item.questionAnswer}}
          </div>
        </div>
      
      </div>
    </div>
    <!--看图说话-->
    <div v-if='list5.lengt>0'>
      <div class="title">
        <div class="line"></div>
        <div>看图说话</div>
      </div>
      <div style="display: flex; flex-direction: column; margin: 30px 0" v-for='(item,index) in list5' :key='index'>
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: #000;
            margin: 0 81px;
          "
        >
          <div style="display: flex; align-items: center">
            <div>{{item.questionContent}}</div>
            <div style="color: #bababa; margin: 0 12px">({{item.score}}分)</div>
            <div style="color: #409eff" >
              查看答案<img
                v-show="answer"
                src="../../images/down.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
              <img
                v-show="!answer"
                src="../../images/up.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
            </div>
          </div>
          <div style="font-size:20px;color:red">{{item.userScore}}分</div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            margin: 15px 0;
            justify-content: space-between;
            margin: 24px 93px;
          "
        >
          <div
            style="
              width: 261px;
              height: 261px;
              background: pink;
              position: relative;
            "
          >
            <div
              style="
                width: 100%;
                height: 32px;
                background: #bababa;
                position: absolute;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
              "
            >
              <div>&lt;</div>
              <div style="display: flex; margin: 0 23px">
                <div>1</div>
                <div>/5</div>
              </div>
              <div>&gt;</div>
            </div>
          </div>
          <div>
            <div style="font-size: 20px; color: #bababa; margin-bottom: 10px">
              考生答案：
            </div>
            <div
              style="
                width: 695px;
                max-height: 232px;
                overflow: auto;
                border: 1px solid #cacaca;
                font-size: 16px;
                line-height: 34px;
                min-height:200px
              "
            >
              <div style="margin: 12px">
               {{item.userQuestionAnswer}}
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end; margin: 0 89px">
          <div
            style="
              width: 100%;
              max-height: 232px;
              overflow: auto;
              border: 1px solid #cacaca;
              padding: 14px;
              margin: 15px 0;
              font-size: 16px;
              line-height: 34px;
              color: #cacaca;
              min-height:200px
            "
            v-show="answer"
          >
             {{item.questionAnswer}}
          </div>
        </div>
        <!-- <div style="display: flex; align-items: center; margin-top: 36px">
          <div style="color: #bababa; font-size: 20px; margin-left: 136px">
            评分：
          </div>
          <el-input
            v-model="score"
            placeholder="请输入分数"
            style="width: 139px"
          ></el-input>
        </div> -->
        <!-- <div>
          <div
            style="
              display: flex;
              font-size: 20px;
              justify-content: center;
              margin-top: 65px;
              padding-bottom: 65px;
            "
          >
            <div class="buttomlr">&lt;</div>
            <div style="line-height: 34px; margin-left: 25px">1</div>
            <div style="color: #e4e4e4; line-height: 34px; margin-right: 25px">
              /5
            </div>
            <div class="buttomlr">&gt;</div>
          </div>
        </div> -->
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import {questionExamList} from '../../apis/index'
export default {
  data() {
    return {
      list3:[],
      list4:[],
      list5:[],
      pageIndex:1,
      pageSize:99,
      questionTypes:['3','4','5'],
      paperId:'',
      score: "",
      answer: true,
      status: 1, //1 填空 2 主观题 3 看图说话
      list: [
        {
          value: "洗脸",
          right: false,
          wrong: false,
        },
        {
          value: "护肤",
          right: false,
          wrong: false,
        },
        {
          value: "睡觉",
          right: false,
          wrong: false,
        },
      ],
    };
  },
  created(){
     this.paperId=this.$route.query.id
     this.questionList()
  },
  methods: {
    goback(){//返回
      this.$router.go(-1)
    },
     async questionList(){
     const params={
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        paperId:this.paperId,
        questionTypes:this.questionTypes
     }
     const {data}=await questionExamList(params)
     if(data.code==200){
       let list=data.data.records
       let list3=list.filter(item=>{
         return item.typeId==3
       })
       let list4=list.filter(item=>{
         return item.typeId==4
       })
       let list5=list.filter(item=>{
         return item.typeId==5
       })

      list3.forEach(item=>{
        item.questionAnswer=item.questionAnswer.replace(/&&&/g,'/')
        if(item.userQuestionAnswer){
          item.userQuestionAnswer=item.userQuestionAnswer.replace(/&&&/g,'/')
        }
      })

     



       this.list3=list3
       this.list4=list4
       this.list5=list5
        console.log(list5)
     }
    
    },
    lookanswer() {
      this.answer = !this.answer;
    },
    select(ischeched, index, name) {
      let list = this.list;
      if (name == "right") {
        list[index].right = !list[index].right;
        list[index].wrong = false;
        this.list = list;
      } else {
        list[index].wrong = !list[index].wrong;
        list[index].right = false;
        this.list = list;
      }
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-input__inner {
  text-align: center;
  color: red;
  // font-size: 20px;
}
.buttomlr {
  width: 34px;
  height: 34px;
  border: 1px solid #e4e4e4;
  line-height: 34px;
  text-align: center;
  border-radius: 6px;
  color: #e4e4e4;
}
.maincontent {
  width: 1200px;
  background: #fff;
  border-radius: 14px;
  margin: 13px 0;
  .title {
    display: flex;
    margin-left: 60px;
    align-items: center;
    .line {
      width: 9px;
      height: 22px;
      background: #409eff;
      border-radius: 6px;
    }
  }
  .title > div:last-child {
    font-size: 20px;
    font-weight: bold;
    margin-left: 11px;
  }
}
</style>
